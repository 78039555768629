import axios from '../axios';

class CoreProvider {

  route;
  constructor() {
    this.route = 'api/Core'
  }

  async getParticularClauses(modalityid) {
    return await axios.get(`${this.route}/GetParticularClauses`, { params: { modalityid } })
  }

  async getProposalParticularClauses(modalityid) {
    return await axios.get(`${this.route}/GetProposalParticularClauses`, { params: { modalityid } })
  }

  async getProposalParticularClausesEndorsement(modalityid, policyUniqueId) {
    return await axios.get(`${this.route}/GetProposalEndorsementParticularClauses`, { params: { modalityid, policyUniqueId} })
  }

  async getModalityById(modalityid, ignoreCache) {
    return await axios.get(`${this.route}/GetModality`, { params: { modalityid, ignoreCache } })
  }

  async getModalityGroupsEnabledToInsured(insuredUniqueId, policyHolderUniqueId) {
    return await axios.get(`${this.route}/GetModalityGroupsEnabledToInsured`, { params: { insuredUniqueId, policyHolderUniqueId } })
  }

  async getComplementaryModalities(modalityUniqueId) {
    return await axios.get(`${this.route}/GetComplementaryModalities`, { params: { modalityUniqueId } })
  }

  async getModalityObjectById(modalityid) {
    return await axios.get(`${this.route}/getModalityObject`, { params: { modalityid } })

  }
}

export default new CoreProvider();
