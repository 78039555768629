<template>
  <div class="particular-clauses-container" v-if="isVisible">
    <p class="font-semibold mt-8">Condições Particulares</p>

    <vs-row v-if="optionsComp && optionsComp.length">
      <vs-col vs-w="12" class="w-full">
        <ValidationProvider name="Condições Particulares" v-slot="{ errors }">
          <v-select
            label="Name"
            multiple
            class="w-full particular-clauses"
            :filterable="false"
            :options="optionsComp"
            :show-no-results="false"
            :value="valueData"
            :loading="loading"
            @input="addClause"
            @change="handleFunctionInput"
            placeholder="Selecione uma ou mais opções"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
          </v-select>

          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </vs-col>
    </vs-row>

    <template v-for="(clause, indexKey) in value">
      <vs-row
        :key="indexKey"
        v-if="
          clause &&
            clause.Id &&
            hasTags(clause.AggregatorClause) &&
            ((particularClausesInputs(clause.AggregatorClause) || []).length ||
              (particularClausesTextArea(clause.AggregatorClause) || []).length)
        "
      >
        <p class="font-semibold mt-8 w-full">
          {{ clause.Name }}
        </p>

        <div
          v-if="
            (particularClausesCampos || []).filter(x => x.Type != 'Automatic')
              .length
          "
        >
          <vs-row class="mt-5">
            <vs-col
              :vs-lg="
                getColValue(particularClausesInputs(clause.AggregatorClause))
              "
              v-for="(input, key) in particularClausesInputs(
                clause.AggregatorClause
              )"
              vs-sm="12"
              :key="key"
            >
              <onpoint-input
                :input="input"
                :validatorRuleObject="{
                  required: true,
                  isNumeric: input.Type == 'Number'
                }"
                @input="handleFunctionInput"
              />
            </vs-col>
          </vs-row>

          <vs-row
            :key="'textarea' + key"
            v-for="(input, key) in particularClausesTextArea(
              clause.AggregatorClause
            )"
          >
            <vs-col vs-w="12">
              <onpoint-input
                :input="input"
                :validatorRuleObject="{ required: true }"
                @input="handleFunctionInput"
              />
            </vs-col>
          </vs-row>

          <template v-if="particularClausesRepeater(clause.Id).length">
            <vs-row
              class="mt-5"
              vs-type="flex"
              v-for="(repeaterObject,
              repeaterIndex) in particularClausesRepeater(clause.Id)"
              :key="'div' + repeaterIndex"
            >
              <p class="font-semibold mb-5">{{ repeaterObject.Label }}</p>

              <vs-card
                class="pt-3 repeater-clause"
                v-for="(repeaterInputs, repeaterKey) in repeaterObject.Repeater"
                :key="'repeater' + repeaterKey"
              >
                <vs-row>
                  <vs-col
                    v-for="(input, inputKey) in repeaterInputs"
                    :key="'input' + inputKey"
                    :vs-lg="getColValue(repeaterInputs)"
                    vs-sm="12"
                  >
                    <onpoint-input
                      :input="input"
                      :validatorRuleObject="{
                        required: true,
                        isNumeric: input.Type == 'Number'
                      }"
                      @input="handleFunctionInput"
                    />
                  </vs-col>
                </vs-row>

                <div slot="header" v-if="repeaterKey > 0">
                  <vs-row vs-justify="flex-end">
                    <vs-button
                      icon="clear"
                      @click="
                        removeRepeaterObject({
                          id: clause.Id,
                          repeaterIndex,
                          repeaterKey
                        })
                      "
                    ></vs-button>
                  </vs-row>
                </div>
              </vs-card>

              <vs-col
                class="py-0"
                vs-w="12"
                vs-justify="end"
                vs-align="center"
                vs-type="flex"
              >
                <vs-button
                  type="flat"
                  icon="add_circle"
                  class="px-1"
                  @click="
                    addNewLineRepeater({
                      params: repeaterObject.Repeater[0],
                      key: repeaterIndex,
                      id: clause.Id
                    })
                  "
                >
                  Adicionar {{ repeaterObject.Label }}
                </vs-button>
              </vs-col>
            </vs-row>
          </template>
        </div>
      </vs-row>
    </template>

    <vs-row v-if="!hideObject && (value || []).length" class="pt-5">
      <vs-col vs-w="12">
        <p class="font-semibold">Objeto das condições particulares</p>

        <p
          class="text-objeto p-5"
          style="font-size: 15px"
          v-html="particularClausesHtmlFormated"
        ></p>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import coreProvider from "@/providers/core-provider";
import TagHelpers from "@/helpers/TagHelpers";
import environment from "@/environment";
import PerfilHelpers from "@/helpers/PerfilHelpers";
export default {
  props: [
    "value",
    "modalityId",
    "proposal",
    "hideObject",
    "options",
    "isEndosso",
    "proposalClausesRequired",
    "isEndossoAlteracao"
  ],
  data() {
    return {
      loading: false,
      particularClausesHtml: null,
      particularClausesHtmlFormated: null,
      particularClausesCampos: [],
      repeaterValue: [],
      optionsParticularClauses: [],
      tagValues: [],
      modalityIdSearched: null,

      profileHelpers: PerfilHelpers
    };
  },
  watch: {
    // particularClauses(value) {
    //   if (value && value.length) this.setOptionsComp();
    // },

    options(value) {
      if (value && value.length) this.setOptionsComp();
    },

    // proposalClausesRequired(value) {
    //   if (value && value.length) this.setOptionsComp();
    // },

    defaultTags(val) {
      if (!val || val.length == 0) return null;
      this.replaceDefaultTags();
      this.formatParticularClausesHtml();
    },

    value(val, oldVal) {
      let clauseGuild = val.map(x => x.AggregatorClause);
      this.particularClausesCampos =
        this.particularClausesCampos.filter(x =>
          (clauseGuild || []).includes(x.AggregatorClause)
        ) || [];

      if (val && val.length > 0) {
        let valueDuplicate = false;

        (val || []).forEach(clauses => {
          if (
            val.filter(
              item =>
                (item || {}).AggregatorClause &&
                (item || {}).AggregatorClause ==
                  (clauses || {}).AggregatorClause
            ).length > 1
          ) {
            valueDuplicate = true;
          }
          if (!valueDuplicate) {
            this.setInputsParticularClauses(clauses);
          }
        });

        // if (valueDuplicate) this.$emit("input", oldVal);
      }

      this.handleFunctionInput();
    },

    async modalityId(val) {
      if (val) {
        await this.getParticularClauses();
      }
    }
  },
  computed: {
    ...mapGetters("proposta-module", ["particularClauses"]),

    userInfo() {
      return this.$store.getters["auth/userInfo"];
    },

    allowedHTML() {
      return [environment.CompanyId.Essor].includes(
        (this.userInfo || {}).InsuranceId
      );
    },

    optionsComp() {
      if (this.isBroker) {
        return this.optionsParticularClauses.filter(
          clauses => !clauses.IsFixedClause
        );
      } else {
        return this.optionsParticularClauses;
      }
    },

    isVisible() {
      return (
        (this.particularClauses && this.particularClauses.length > 0) ||
        (this.options && this.options.length) ||
        (this.value && (this.value || []).length)
      );
    },

    isBroker() {
      return this.profileHelpers.isBroker();
    },

    valueData() {
      if (this.isBroker)
        return (this.value || []).filter(x => !x.IsFixedClause);
      return this.value || [];
    },

    defaultTags() {
      return TagHelpers.defaultTags(this.proposal);
    }
  },
  methods: {
    ...mapMutations("proposta-module", ["updateParticularClauses"]),

    addClause(event) {
      let termsIds = [];
      let item;
      let oldIds = (
        this.value.filter(y =>
          event.map(x => x.AggregatorClause).includes(y.AggregatorClause)
        ) || []
      ).map(x => x.AggregatorClause);
      let newIds = event
        .map(x => x.AggregatorClause)
        .filter(x => !oldIds.includes(x));

      if (this.isBroker)
        oldIds = oldIds.concat(
          this.value
            .filter(x => x.IsFixedClause)
            .map(x => x.AggregatorClause) || []
        );

      item = this.value
        .filter(x => oldIds.includes(x.AggregatorClause))
        .concat(event.filter(x => newIds.includes(x.AggregatorClause)));

      (item || []).forEach(element => {
        termsIds.push(element.ContractualTermId);
        let clause = this.optionsParticularClauses.find(
          x => x.AggregatorClause == element.AggregatorClause
        );
        element.ContractualTerm.JSONTagValue =
          element.ContractualTerm.JSONTagValue ||
          clause.ContractualTerm.JSONTagValue;
      });

      for (var i = 0; i < this.particularClausesCampos.length - 1; i++) {
        if (
          !termsIds.includes(this.particularClausesCampos[i].ContractualTermId)
        )
          this.particularClausesCampos.splice(i, 1);
      }

      this.$emit("input", item);
    },

    getColValue(listCampos) {
      let totalItens = listCampos ? listCampos.length : 0;

      if (totalItens >= 4) return 3;
      else if (totalItens == 3) return 4;
      else if (totalItens == 2) return 6;
      else if (totalItens == 1) return 12;

      return null;
    },

    /*
     * Start
     * Filter inputs by type
     */
    particularClausesRepeater(id) {
      return (
        (
          this.particularClausesCampos.find(x => x.ParticularClauseId == id) ||
          {}
        ).Reteaters || []
      );
    },

    particularClausesInputs(id) {
      return (
        (this.particularClausesCampos.find(x => x.AggregatorClause == id) || {})
          .Campos || []
      ).filter(input => !["TextArea", "Automatic"].includes(input.Type));
    },

    particularClausesTextArea(id) {
      return (
        (this.particularClausesCampos.find(x => x.AggregatorClause == id) || {})
          .Campos || []
      ).filter(input => input.Type == "TextArea" && input.Type != "Automatic");
    },
    /*
     * End
     * Filter inputs by type
     */

    replaceDefaultTags() {
      const ignoreTags = (this.particularClausesCampos || []).map(input => {
        return input.Name;
      });

      this.particularClausesHtmlFormated = TagHelpers.replaceDefaultTags(
        this.particularClausesHtml,
        this.defaultTags,
        ignoreTags
      );
    },

    handleFunctionInput() {
      this.mountParticularClausesHtml();
      this.sendChange();
    },

    sendChange() {
      this.$emit("proposal-terms-values-change", this.getProposalTermsValues());
    },

    mountParticularClausesHtml: _.debounce(function() {
      this.formatParticularClausesHtml();
    }, 500),

    addNewLineRepeater(item) {
      if (
        ((
          this.particularClausesCampos.find(
            x => x.ParticularClauseId == item.id
          ) || {}
        ).Reteaters || [])[item.key].Repeater.length > 9
      )
        return;

      const objectInputs = [];
      (item.params || []).map(inputs => {
        objectInputs.push({
          Name: inputs.Name,
          Label: inputs.Label,
          Type: inputs.Type
        });
      });
      ((
        this.particularClausesCampos.find(
          x => x.ParticularClauseId == item.id
        ) || {}
      ).Reteaters || [])[item.key].Repeater.push(objectInputs);
      this.formatParticularClausesHtml();
    },

    removeRepeaterObject(item) {
      (
        this.particularClausesCampos.find(
          clause => clause.ParticularClauseId == item.id
        ).Reteaters[item.repeaterIndex].Repeater || []
      ).splice(item.repeaterKey, 1);
      this.formatParticularClausesHtml();
    },

    setOptionsComp() {
      let options = this.$utils.deepClone(
        this.options || this.particularClauses || []
      );

      (options || []).forEach(clause => {
        let required = (this.proposalClausesRequired || []).find(
          proposalClause =>
            proposalClause.AggregatorClause == clause.AggregatorClause
        );

        let initValue = (this.valueData || []).find(
          proposalClause =>
            proposalClause.AggregatorClause == clause.AggregatorClause
        );

        if (!!required)
          clause.ContractualTerm = {
            ...clause.ContractualTerm,
            JSONTagValue: required.ContractualTerm.JSONTagValue
          };

        if (!!initValue)
          clause.ContractualTerm = {
            ...clause.ContractualTerm,
            JSONTagValue: initValue.ContractualTerm.JSONTagValue
          };
      });

      this.optionsParticularClauses = options;
      if (this.isBroker) {
        this.$emit(
          "input",
          options.filter(clause => clause.IsFixedClause).concat(this.valueData)
        );
      } else {
        this.$emit("input", this.value);
      }
    },

    async getParticularClauses() {
      if (!this.options || !this.options.length)
        if (
          !this.particularClauses ||
          !this.particularClauses.length ||
          this.modalityId != this.modalityIdSearched
        ) {
          if (!this.modalityId) return;

          this.loading = true;
          let res;
          if (this.isEndossoAlteracao) {
            res = await coreProvider.getProposalParticularClausesEndorsement(
              this.modalityId,
              this.$route.params.policyUniqueId
            );
          } else {
            res = await coreProvider.getProposalParticularClauses(
              this.modalityId
            );
          }
          this.loading = false;

          if (res.status == 200) {
            this.updateParticularClauses(res.data.Response);
            this.modalityIdSearched = this.$utils.deepClone(this.modalityId);
          }
        }

      this.setOptionsComp();
      this.$forceUpdate();
    },

    getTagInputs(particularClause) {
      const jsonTagList = particularClause.ContractualTerm.JSONTag
        ? JSON.parse(particularClause.ContractualTerm.JSONTag)
        : [];

      let exists =
        this.particularClausesCampos.filter(
          input => input.ContractualTermId == particularClause.ContractualTermId
        ).length > 0;

      if (exists) return [];

      const values = particularClause.ContractualTerm.JSONTagValue
        ? JSON.parse(particularClause.ContractualTerm.JSONTagValue)
        : {};

      (jsonTagList || []).forEach(element => {
        element.Value = values[element.Name];
      });

      const jsonTagInputs = (
        jsonTagList.filter(input => !["Automatic"].includes(input.Type)) || []
      ).map(input => {
        input.JSONTag = particularClause.ContractualTerm.JSONTag;
        input.ContractualTermId = particularClause.ContractualTerm.Id;
        return input;
      });

      return jsonTagInputs;
    },

    setRepeaterInputs(particularClause) {
      let repeaterArray = [];
      const jsonTagList = particularClause.ContractualTerm.JSONTag
        ? JSON.parse(particularClause.ContractualTerm.JSONTag)
        : [];

      // const values = particularClause.ContractualTerm.JSONTagValue
      //   ? JSON.parse(particularClause.ContractualTerm.JSONTagValue)
      //   : {};

      //   jsonTagList.forEach(element => {
      //     element.Value = values[element.Name]
      //   });

      (
        (jsonTagList || []).filter(input => input.Type === "Repeater") || []
      ).forEach(repeaterInput => {
        repeaterArray.push({
          Name: repeaterInput.Name,
          Label: repeaterInput.Label,
          Repeater: [repeaterInput.Repeater],
          Text: repeaterInput.Text,
          ContractualTermId: particularClause.ContractualTerm.Id,
          AggregatorClause: particularClause.AggregatorClause
        });
      });

      return repeaterArray;
    },

    setParticularClausesHtml(particularClause) {
      this.particularClausesHtml += (
        (particularClause || {}).ContractualTerm || {}
      ).Value
        ? ((particularClause || {}).ContractualTerm || {}).Value + "<br/>"
        : `Não há texto para a clausula ${particularClause.Name}`;
      this.particularClausesHtmlFormated = this.particularClausesHtml;
    },

    hasTags(id) {
      return !!this.particularClausesCampos.filter(
        x => x.AggregatorClause == id
      ).length;
    },

    setInputsParticularClauses(particularClause) {
      if (
        !particularClause ||
        !particularClause.Id ||
        this.hasTags(particularClause.AggregatorClause)
      )
        return;

      let inputs = [];
      const tagInputs = this.getTagInputs(particularClause);

      if (tagInputs.length > 0) inputs = tagInputs;

      if (((particularClause || {}).ContractualTerm || {}).Id)
        this.particularClausesCampos.push({
          ContractualTermId: particularClause.ContractualTerm.Id,
          ParticularClauseId: particularClause.Id,
          AggregatorClause: particularClause.AggregatorClause,
          Name: particularClause.Name,
          Campos: inputs,
          Reteaters: this.setRepeaterInputs(particularClause),
          Value: particularClause.ContractualTerm.Value
        });

      this.formatParticularClausesHtml();
      this.setTagValues();
    },
    filterIncompletTags(particularClause) {
      const jsonTagList = particularClause.ContractualTerm.JSONTag
        ? JSON.parse(particularClause.ContractualTerm.JSONTag)
        : [];

      let exists =
        this.particularClausesCampos.filter(
          input => input.ContractualTermId == particularClause.ContractualTermId
        ).length > 0;

      if (exists) return [];

      const values = particularClause.ContractualTerm.JSONTagValue
        ? JSON.parse(particularClause.ContractualTerm.JSONTagValue)
        : {};

      (jsonTagList || []).forEach(element => {
        element.Value = values[element.Name];
      });

      const jsonTagInputs = (
        jsonTagList.filter(input => !["Automatic"].includes(input.Type)) || []
      ).map(input => {
        input.JSONTag = particularClause.ContractualTerm.JSONTag;
        input.ContractualTermId = particularClause.ContractualTerm.Id;
        return input;
      });

      return jsonTagInputs;
    },
    formatParticularClausesHtml() {
      this.particularClausesHtml = "";
      this.particularClausesHtmlFormated = this.particularClausesHtml;

      (this.particularClausesCampos || []).forEach(clauseTags => {
        let clauseObject = (
          (
            (this.value || []).find(
              x => x.AggregatorClause == clauseTags.AggregatorClause
            ) || {}
          ).ContractualTerm || {}
        ).Value;
        (clauseTags.Campos || []).map(input => {
          if (input.Type === "Date" && input.Value) {
            let dateValue = new Date(input.Value);
            dateValue = dateValue.toLocaleDateString("pt-BR");
            clauseObject = (clauseObject || "").replaceAll(
              `[${input.Name}]`,
              `<span class="font-semibold text-danger">${dateValue}</span>`
            );
          } else {
            clauseObject = input.Value
              ? (clauseObject || "").replaceAll(
                  `[${input.Name}]`,
                  `<span class="font-semibold text-danger">${input.Value}</span>`
                )
              : (clauseObject || "").replaceAll(
                  `[${input.Name}]`,
                  `<span class="font-semibold text-danger">${
                    input.Type == "Automatic"
                      ? "[" + input.Name + "]"
                      : input.Label
                  }</span>`
                );
          }
        });

        (clauseTags.Reteaters || []).map(repeaterObject => {
          let objeto = repeaterObject.Text;
          clauseObject =
            //this.particularClausesHtmlFormated.replaceAll("[REPEATER]", "");
            (clauseObject || "").replaceAll(
              `[REPEATER]`,
              `<span class="font-semibold text-danger">${repeaterObject.Label}</span>`
            );
          let i = 1;
          ((repeaterObject || {}).Repeater || []).forEach(tagRepeater => {
            (tagRepeater || []).map(inputObject => {
              if (inputObject) {
                if (i > 1) {
                  let tag = inputObject.Name.replaceAll(`_${i}`, "");
                  objeto = objeto.replaceAll(
                    `[${tag}]`,
                    `[${inputObject.Name}]`
                  );
                }

                objeto = inputObject.Value
                  ? objeto.replaceAll(
                      `[${inputObject.Name}]`,
                      `<span class="font-semibold text-danger">${inputObject.Value}</span>`
                    )
                  : objeto.replaceAll(
                      `[${inputObject.Name}]`,
                      `<span class="font-semibold text-danger">${
                        inputObject.Type == "Automatic"
                          ? "[" + inputObject.Name + "]"
                          : inputObject.Label
                      }</span>`
                    );
              }
            });

            clauseObject += "" + objeto;
            objeto = repeaterObject.Text;
          });

          i++;
        });

        clauseTags.Value = clauseObject;

        if (clauseTags.Value)
          this.particularClausesHtml += clauseTags.Value + "<br /><br />";
      });

      this.replaceDefaultTags();
    },

    createRepeaterValuesObject(id) {
      let repeaterList = [];
      (this.particularClausesRepeater(id) || []).forEach(repeaterObject => {
        let repeaterItem = {};
        repeaterItem.name = repeaterObject.Name;
        repeaterItem.contractualTermId = repeaterObject.ContractualTermId;
        repeaterItem.items = (repeaterObject.Repeater || []).map(repeaters => {
          let obj = {};
          (repeaters || []).forEach(r => (obj[r.Name] = r.Value));
          return obj;
        });
        repeaterList.push(repeaterItem);
      });
      return repeaterList;
    },

    getProposalTermsValues() {
      if (!this.value || !this.value.length) return;

      let clauseIds = [];

      let proposalTermsValues = (this.value || []).map(clauses => {
        let valueContract = ((clauses || {}).ContractualTerm || {}).Value;

        let ignoreTagsList = [];
        (this.particularClausesCampos || []).forEach(camposIgnore => {
          ignoreTagsList = ignoreTagsList.concat(
            (camposIgnore.Campos || []).map(input => {
              return input.Name;
            })
          );
        });
        const ignoreTags = ignoreTagsList;

        valueContract = TagHelpers.replaceDefaultTags(
          valueContract,
          this.defaultTags,
          ignoreTags
        );
        let valutesTags = {};

        if (((clauses || {}).ContractualTerm || {}).JSONTag)
          (
            JSON.parse(((clauses || {}).ContractualTerm || {}).JSONTag) || []
          ).map(tag => {
            (
              (
                this.particularClausesCampos.find(
                  x => x.ParticularClauseId == clauses.Id
                ) || {}
              ).Campos || []
            ).map(input => {
              if (input.Type === "Date") {
                var dateValue = new Date(input.Value);
                dateValue = dateValue.toLocaleDateString("pt-BR");
                valueContract = valueContract.replaceAll(
                  `[${input.Name}]`,
                  `${dateValue || ""}`
                );
              } else {
                valueContract = valueContract.replaceAll(
                  `[${input.Name}]`,
                  `${input.Value || ""}`
                );

                if (valueContract.indexOf(`[${input.Name}_EXT]`) >= 0) {
                  const valueExt = TagHelpers.parseTagNumberValueExtenso(
                    input.Value
                  );
                  if (valueExt)
                    valueContract = valueContract.replaceAll(
                      `[${input.Name}_EXT]`,
                      valueExt
                    );
                }
              }
              if (tag.Name === input.Name) {
                if (input.Type === "Date") {
                  valutesTags = { ...valutesTags, [input.Name]: dateValue };
                } else {
                  valutesTags = { ...valutesTags, [input.Name]: input.Value };
                }
              }
            });
          });

        (
          this.particularClausesRepeater(clauses.Id).filter(
            repeater => repeater.ContractualTermId == clauses.ContractualTerm.Id
          ) || []
        ).map(repeaterObject => {
          // valueContract = valueContract.replaceAll("[REPEATER]", "");
          valueContract = valueContract.replaceAll(
            `[${repeaterObject.Name}]`,
            ""
          );
          let objeto = repeaterObject.Text;
          let i = 1;
          (repeaterObject.Repeater || []).map(inputObject => {
            (inputObject || []).map(input => {
              objeto = objeto.replaceAll(
                `[${input.Name}]`,
                `${input.Value || ""}`
              );
            });
            valueContract += "<br /><br />" + objeto;
            objeto = repeaterObject.Text;
            i++;
          });
        });

        if (this.particularClausesRepeater(clauses.Id).length > 0) {
          const repeaters = this.createRepeaterValuesObject(clauses.Id);
          const repeatersInContractTerm = (repeaters || []).find(
            repeater => repeater.contractualTermId == clauses.ContractualTerm.Id
          );
          if (repeatersInContractTerm)
            valutesTags.REPEATERS = this.createRepeaterValuesObject(clauses.Id);
        }

        clauseIds.push(clauses.AggregatorClause);

        if (valutesTags)
          (this.optionsParticularClauses || []).forEach(option => {
            if (option.AggregatorClause == clauses.AggregatorClause)
              option.ContractualTerm.JSONTagValue = JSON.stringify(valutesTags);
          });

        return {
          proposalId: this.proposal ? this.proposal.Id : null,
          JSONTag: ((clauses || {}).ContractualTerm || {}).JSONTag,
          ContractualTermId: (clauses || {}).ContractualTermId,
          JSONTagValue: JSON.stringify(valutesTags),
          Value: valueContract
        };
      });

      (this.optionsParticularClauses || []).forEach(option => {
        if (!clauseIds.includes(option.AggregatorClause)) {
          option.ContractualTerm.JSONTagValue = null;
        }
      });

      return proposalTermsValues;
    },

    addNewLineTypeRepeaterByTagValues(item) {
      if (item.repeaterObject.Repeater.length < item.repeaters.length) {
        let qtToAdd =
          item.repeaters.length - item.repeaterObject.Repeater.length;

        for (let index = 0; index < qtToAdd; index++) {
          this.addNewLineRepeater({
            params: item.repeaterObject.Repeater[0],
            key: this.particularClausesRepeater(item.id).indexOf(
              item.repeaterObject
            ),
            id: item.id
          });
        }
      }
    },

    setTagRepeatersValues() {
      if (!this.value || !this.value.length) return;

      (this.particularClausesCampos || []).forEach(clauseCampo => {
        (
          (this.value || []).filter(
            tagValue =>
              (clauseCampo || {}).ContractualTermId ==
                (tagValue || {}).ContractualTermId &&
              ((tagValue || {}).ContractualTerm || {}).JSONTagValue
          ) || []
        ).forEach(tagValue => {
          let jsonTagValue = JSON.parse(
            ((tagValue || {}).ContractualTerm || {}).JSONTagValue
          );

          if (
            !jsonTagValue["REPEATERS"] ||
            !Array.isArray(jsonTagValue["REPEATERS"])
          )
            return;
          let repeaterList = jsonTagValue["REPEATERS"];

          let repeaterArray = clauseCampo.Reteaters.filter(
            repeaterObj =>
              repeaterObj.ContractualTermId == tagValue.ContractualTermId
          );

          if (!repeaterArray || repeaterArray.length == 0) return;

          (repeaterList || []).forEach(repeaterItem => {
            let repeaterObject = (repeaterArray || []).find(
              repeaterObj =>
                repeaterObj.Name == repeaterItem.name &&
                repeaterObj.ContractualTermId == repeaterItem.contractualTermId
            );

            if (!repeaterObject) return;

            let repeaters = repeaterItem.items;

            this.addNewLineTypeRepeaterByTagValues({
              id: tagValue.Id,
              repeaters,
              repeaterObject
            });

            (repeaters || []).forEach((repeater, index) => {
              let repeaterProperties = Object.getOwnPropertyNames(repeater);
              let inputs = repeaterObject.Repeater[index];
              (repeaterProperties || []).forEach(repeaterProperty => {
                let input = (inputs || []).find(
                  i => i.Name == repeaterProperty
                );
                if (input) {
                  if (!input.Value) input.Value = repeater[repeaterProperty];
                }
              });
            });
          });
        });
      });
      this.handleFunctionInput();
    },

    setTagValues() {
      if (!this.value || !this.value.length) return;
      (
        (this.value || []).filter(
          tagValue => ((tagValue || {}).ContractualTerm || {}).JSONTagValue
        ) || []
      ).forEach(tagValue => {
        let jsonTagValue = JSON.parse(
          ((tagValue || {}).ContractualTerm || {}).JSONTagValue
        );

        let properties = Object.getOwnPropertyNames(jsonTagValue);

        if (properties.length == 0) return;

        (properties || []).forEach(property => {
          let input = (
            (
              (this.particularClausesCampos || []).find(
                x => x.AggregatorClause == tagValue.AggregatorClause
              ) || {}
            ).Campos || []
          ).find(input => {
            return (
              input.Name == property &&
              input.ContractualTermId == tagValue.ContractualTermId
            );
          });

          if (input && !input.Value) input.Value = jsonTagValue[property];
          //else
          //   jsonTagValue["REPEATERS"].forEach(repeater => {
          //     if (repeater.name == input.Name) {
          //       input.Reteaters = [];
          //       repeater.itens.forEach;
          //     }
          //   });
        });
      });

      this.setTagRepeatersValues();
    }
  },
  mounted() {
    this.getParticularClauses().then(() => {
      if (this.value && this.value.length)
        (this.value || []).forEach(clause => {
          this.setInputsParticularClauses(clause);
        });
    });
  }
};
</script>

<style lang="scss" scoped>
.particular-clauses {
  width: 100%;
  ::v-deep .vs__selected {
    background-color: transparent;
    border-color: transparent;
    font-weight: bold;
  }
  ::v-deep .vs__dropdown-toggle {
    height: auto !important;
    min-height: 50px !important;
    padding: 10px !important;
    .vs__valueed {
      width: auto !important;
    }
  }
}

.text-objeto {
  border: solid 1px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  border-radius: 8px !important;
}
</style>

<style lang="scss">
.particular-clauses-container {
  height: auto;

  .repeater-clause {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0;
    margin-bottom: 25px;

    .vs-card--content {
      margin-bottom: 0 !important;
    }

    .vs-card--header {
      position: relative;
      padding: 10px;
      margin-top: 0;
      padding-top: 0;

      .includeIconOnly {
        width: 28px !important;
        height: 28px !important;
      }
    }
  }

  .vs-col {
    padding: 1.5rem 0.5rem;
  }

  .vs__selected-options {
    input {
      height: 0px;
    }
  }
}
</style>
